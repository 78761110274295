import React, {useEffect, useState} from 'react'
import dynamic from 'next/dynamic'
import {ITokenDistribution} from '@type/profile'
import useFormatNumber from '@hook/useFormatNumber'
import useDark from '@hook/useDark'
import {DarkModeStore} from '@store/DarkmodeStore'

const ApexChart = dynamic(() => import('react-apexcharts'), {ssr: false})

interface IProps {
    width?: number
    height?: number
    input?: ITokenDistribution[]
    className?: string
    donutSize?: string
}

const CoinDistributionChart: React.FC<IProps> = ({width, height, input = [], className, donutSize}) => {
    const [data, setData] = useState<number[]>([])
    const [label, setLabel] = useState<string[]>([])
    const [colors, setColors] = useState<string[]>(['#14B67E', '#95D8FF', '#6D44D1', '#FFD731', '#D0D0D0'])
    const {displayPercent} = useFormatNumber()
    const {isRenderDark} = useDark()
    const {isCheckDark} = DarkModeStore()

    useEffect(() => {
        function initInputs() {
            let list = []
            let colorList = []

            if (input.length > 0) {
                input.map(item => {
                    list.push(item.percentage)
                    colorList.push(item.color_code ?? '#14B67E')
                })
            } else {
                list.push(100)
                colorList.push('#EAEAEA')
            }

            setData(list)
            setColors(colorList)
        }

        function initLabels() {
            let list = []

            if (input.length > 0) {
                input.map(item => {
                    list.push(`${item.symbol} (${item.name})`)
                })
            }

            setLabel(list)
        }

        initInputs()
        initLabels()
    }, [input])

    return (
        <div className={className}>
            <ApexChart
                options={{
                    chart: {
                        type: 'donut',
                        sparkline: {
                            enabled: true,
                        },
                    },
                    tooltip: {
                        enabled: true,
                        y: {
                            formatter: function (value) {
                                return displayPercent(value)
                            },
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: donutSize ?? '60%',
                            },
                            expandOnClick: false,
                        },
                    },
                    fill: {
                        colors: colors,
                    },
                    labels: label,
                    stroke: {
                        colors: [isCheckDark && isRenderDark ? '#2E2E31' : '#FFFFFF'],
                    },
                }}
                series={data}
                type="donut"
                width={width}
                height={height}
            />
        </div>
    )
}

export default CoinDistributionChart
