import React from 'react'
import IconSharePortfolio from '@svg/common/ic_btn_share.svg'
import {useTranslation} from 'react-i18next'
import Text from '@component/Text/Text'

interface IProps {
    className?: string
    onClick?: () => void
    text?: string
}

const SharePortfolioButton: React.FC<IProps> = ({className, onClick, text}) => {
    const {t} = useTranslation()
    return (
        <div className={'w-full mt-[10px]'}>
            <button
                onClick={onClick}
                className={`bg-white dark:bg-bg_dark_white03 gap-x-[5px] py-[10px] border border-gray06 dark:border-dark_gray06 rounded-[20px] px-[20px] mx-auto justify-center flex items-center hover:bg-gray09 dark:hover:bg-dark_gray09 active:bg-gray07 dark:active:bg-dark_gray07 ${className}`}>
                <IconSharePortfolio className={'w-[12px] fill-primary dark:fill-dark_primary'} />
                <Text
                    enablePreWhiteSpace={false}
                    className={'text-body3 whitespace-nowrap text-gray01 dark:text-dark_gray01'}>
                    {text}
                </Text>
            </button>
        </div>
    )
}

export default SharePortfolioButton
